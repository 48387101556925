<template>
  <layout-stepper>
    <template #body>
      <b-link @click="$router.push({name: 'step_description'})" v-if="!has_slug">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        Previous Step
      </b-link>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">Where are you based?</h1>
            <p>Buyers want to make sure they're reaching the right audience geographically, or that their product/service can be found in or sent to your location.</p>     

            <b-form-select :options="options_countries" v-model="country_selected" class="mt-1"></b-form-select>
            <b-form-input v-model="cp" class="mt-1" placeholder="Codigo postal"></b-form-input>
            <b-col class="col-12 d-flex justify-content-center">
              <b-button variant="primary" class="my-2" :disabled="cp === ''" @click="save()">{{has_slug ? 'Save and preview' : 'Next step'}}</b-button>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import utils from '@/libs/utils';
import countries from '@/libs/countries.json';
import creator_services from '@/services/creator';

export default {
  name: 'location',
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BFormInput,
    BFormSelect,
    layoutStepper,
  },
  data() {
    return {
      cp: '',
      profile: {},
      utils,
      steps: null,
      country_selected: 'MX',
      options_countries: [],
      preferred_countries: ['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US'],
      proposal: {}
    }
  },
  created() {
    this.getData();
    this.getCountries();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    getData() {
      if (this.$route.params.slug !== undefined) { 
        creator_services.detailProposal(this.$route.params.slug).then((response) => {
          this.country_selected = response.country.toUpperCase();
          this.cp = response.zipcode;
          this.proposal = response;
        });
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        if (this.steps.location) {
          this.country_selected = this.steps.location.country;
          this.cp = this.steps.location.cp;
        }
      }
    },
    getCountries() {
      countries.map((item) => {
        if (this.preferred_countries.find((preferred) => preferred === item['alpha-2']) !== undefined) {
          this.options_countries.unshift({value: item['alpha-2'], text: item.name})
        } else {
          this.options_countries.push({value: item['alpha-2'], text: item.name})
        }
      });
    },
    save() {
      if (this.$route.params.slug !== undefined) { 
        creator_services.updateProposal(this.proposal.uuid, {country: this.country_selected.toLowerCase(), zipcode: this.cp}).then(() => {
          this.$router.push({name: 'step_preview', params: {slug: this.proposal.slug}})
        });
      } else {
        this.steps['location'] = {country: this.country_selected, cp: this.cp};
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({name: 'step_deals'});
      }
    },
  },
}
</script>